<template>
  <div class="outputs-filters">
    <div v-if="loading" class="outputs-filters-loading">
      <b-loading :is-full-page="false" active />
    </div>
    <div
      v-for="filter in loading ? 0 : filters"
      :key="filter.id"
      class="outputs-filters-filter"
    >
      <p class="outputs-filters-filter-header">{{ filter.name }}</p>
      <div class="outputs-filters-filter-list">
        <div
          v-for="option in filter.items"
          :key="option.id"
          class="outputs-filters-filter-list-item"
          @click="() => $emit('select', filter, option)"
        >
          <Checkbox
            :value="isSelected(filter, option)"
            class="outputs-filters-filter-list-item-checkbox"
          />
          <p class="outputs-filters-filter-list-item-name">{{ option.name }}</p>
          <p
            class="outputs-filters-filter-list-item-selectall"
            @click.stop="() => $emit('selectAll', filter, option)"
          >
            Select all
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Checkbox from '@c/library/Checkbox.vue'

export default {
  name: 'MeetingOutputsFilters',
  components: { Checkbox },
  props: {
    filters: {
      type: Array,
      default: () => []
    },
    selected: {
      type: Object,
      default: () => ({})
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    isSelected(filter, option) {
      return (
        this.selected[filter.id]?.length &&
        this.selected[filter.id].includes(option.id)
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.outputs-filters {
  display: flex;
  flex-flow: column nowrap;
  gap: 1.5rem;
  max-height: 100%;
  overflow-y: auto;
  padding: 1.5rem;

  &-loading {
    position: relative;
    min-height: 5rem;
  }

  &-filter {
    display: flex;
    flex-flow: column nowrap;
    gap: 0.75rem;

    &-header {
      font-weight: 700;
    }

    &-list {
      display: flex;
      flex-flow: column nowrap;
      gap: 0.25rem;

      &-item {
        padding: 0.5rem;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        gap: 0.5rem;
        border-radius: 2px;
        cursor: pointer;

        &:hover {
          background: rgba(#000, 0.04);

          & .outputs-filters-filter-list-item-selectall {
            opacity: 1;
          }
        }

        &-selectall {
          color: $primary;
          cursor: pointer;
          opacity: 0;
          transition: opacity 0.2s;
          margin-left: auto;

          &:hover {
            font-weight: 700;
          }
        }

        &-checkbox {
          pointer-events: none;
        }
      }
    }
  }
}
</style>
