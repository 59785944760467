<template>
  <div class="outputs-selection">
    <p class="outputs-selection-header">Selection</p>
    <div
      v-for="template in selected"
      :key="template.uuid"
      class="outputs-selection-item"
    >
      <img
        :src="
          require(`@/assets/file_type/${
            template.type === 'presentation' ? 'powerpoint' : 'word'
          }.svg`)
        "
        alt=""
        class="outputs-selection-item-icon"
      />
      <p class="outputs-selection-item-name">{{ template.name }}</p>
      <div class="outputs-selection-item-action">
        <LanguageSelect
          v-if="!hideSettings && template.type !== 'presentation'"
          :value="languageSetting(template)"
          :position-fixed="true"
          :short="true"
          :content-style="{ minWidth: '10rem' }"
          @click.native.stop
          @input="(language) => handleLanguageChange(template, language)"
        />
        <Button
          icon="bin"
          type="white"
          size="xs"
          @click="() => handleItemClick(template)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Button from '@c/library/Button.vue'
import LanguageSelect from '@c/library/LanguageSelect.vue'

export default {
  name: 'MeetingOutputsSelection',
  components: { LanguageSelect, Button },
  props: {
    selected: {
      type: Array,
      default: () => []
    },
    settings: {
      type: Object,
      default: () => ({})
    },
    meeting: {
      type: Object,
      default: () => ({})
    },
    hideSettings: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    removeLoading: []
  }),
  methods: {
    handleItemClick(template) {
      this.$emit('select', template)
    },
    languageSetting(template) {
      return this.settings[template.uuid].language
    },
    handleLanguageChange(template, language) {
      this.$emit('settings', template, {
        ...this.settings[template.uuid],
        language
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.outputs-selection {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  height: 100%;
  max-height: 100%;
  overflow-y: auto;

  &-header {
    font-size: 1.25rem;
    font-weight: 700;
    padding: 1rem;
    border-bottom: 1px solid rgba(#000, 0.08);
  }

  &-item {
    padding: 0.75rem 1rem;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 0.75rem;
    border-bottom: 1px solid rgba(#000, 0.08);
    cursor: pointer;

    &:hover {
      background: rgba(#000, 0.04);
    }

    &-check {
      pointer-events: none;
    }

    &-icon {
      height: 1.5rem;
    }

    &-name {
      flex: 1;
    }

    &-remove {
      margin-left: auto;
      opacity: 0;
      transition: opacity 0.2s;
    }

    &-action {
      margin-left: auto;
      display: flex;
      flex-flow: row nowrap;
      gap: 0.5rem;
      align-items: center;
    }
  }
}
</style>
