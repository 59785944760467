<template>
  <div class="meeting-audience">
    <p class="meeting-audience-header">Who will you be meeting?</p>
    <AccountPersonSearch
      ref="audiencesearch"
      :account="account"
      :allow-custom="true"
      :position-fixed="true"
      class="meeting-audience-block"
      @submit="addFromSearch"
      @custom="addCustom"
    />
    <div
      v-if="selection && selection.length"
      class="meeting-audience-items meeting-audience-block"
    >
      <div
        v-for="person in selection"
        :key="person.linkedin_url || person.role"
        class="meeting-audience-items-item"
      >
        <div class="meeting-audience-items-item-info">
          <Avatar
            :user="
              person.linkedin_url
                ? { avatar: person.avatar, username: $umodel.full_name(person) }
                : {}
            "
            fallback-icon="user"
            size="m"
          />
          <div class="meeting-audience-items-item-content">
            <div class="meeting-audience-items-item-name">
              <span>{{
                person.first_name
                  ? $umodel.full_name(person)
                  : person.role || person.linkedin_url
              }}</span>
              <img
                v-if="person.linkedin_url"
                src="@/assets/logo/linkedin.svg"
                alt=""
                class="meeting-audience-items-item-link"
                @click.stop="() => gotoLinkedIn(person)"
              />
              <Button
                icon="bin"
                type="white"
                size="xs"
                class="meeting-audience-items-item-remove"
                @click="$emit('remove', person)"
              />
            </div>
            <p
              v-if="person.first_name && person.role"
              class="meeting-audience-items-item-role"
            >
              {{ person.role }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <MeetingORISuggestions
      type="audience"
      :loading="suggestionsLoading"
      :error="suggestionsError"
      :selected="selection"
      :suggestions="suggestions"
      @retry="loadSuggestions"
      @add="addFromSearch"
    />
  </div>
</template>

<script>
import Button from '@c/library/Button.vue'
import AccountPersonSearch from '../organisations/AccountPersonSearch.vue'
import Avatar from '@c/library/Avatar.vue'
import MeetingORISuggestions from './ori/MeetingORISuggestions.vue'
import { getAccountPersonSuggestions } from '@/services/accountService'

export default {
  name: 'MeetingAudience',
  components: {
    Button,
    AccountPersonSearch,
    Avatar,
    MeetingORISuggestions
  },
  props: {
    selection: {
      type: Array,
      default: () => []
    },
    account: {
      type: Object,
      default: undefined
    }
  },
  data: () => ({
    suggestions: [],
    suggestionsLoading: false,
    suggestionsError: false
  }),
  created() {
    this.loadSuggestions()
  },
  methods: {
    id(person) {
      return person.linkedin_url || person.role
    },
    gotoLinkedIn(person) {
      window.open(person.linkedin_url, '_blank')
    },
    addFromSearch(person) {
      // Input format: { avatar, first_name, last_name, id, role, url }
      // Output format: { avatar, name, linkedin_url, role }
      this.$emit('input', {
        avatar: person.avatar,
        first_name: person.first_name,
        last_name: person.last_name,
        linkedin_url: person.url,
        role: person.role
      })
    },
    addCustom(type, val) {
      // Input format: val = role / url
      // Output format: { role, linkedin_url }
      this.$emit(
        'input',
        type === 'linkedin' ? { linkedin_url: val } : { role: val }
      )
    },
    async loadSuggestions() {
      try {
        this.suggestionsError = false
        this.suggestionsLoading = true
        this.suggestions = await getAccountPersonSuggestions({
          workspace_id: this.$route.params.workspace_id,
          account_id: this.account.uuid
        })
      } catch (e) {
        this.suggestionsError = true
      } finally {
        this.suggestionsLoading = false
      }
    },
    blur() {
      this.$refs.audiencesearch.blur()
    }
  }
}
</script>

<style scoped lang="scss">
.meeting-audience {
  &-header {
    font-size: 0.85rem;
    color: #60666b;
    padding-bottom: 0.25rem;
  }

  &-block {
    padding-bottom: 1rem;
  }

  &-items {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0.5rem;

    &-item {
      border-radius: 6px;
      border: 1px solid rgba(#000, 8%);

      background: rgba(#115efb, 4%);

      &:hover {
        background: rgba(#115efb, 8%);
      }

      &-info {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        gap: 1rem;
        padding: 0.5rem 1rem;
      }

      &-content {
        display: flex;
        flex-flow: column nowrap;
        flex: 1;
        gap: 0.25rem;
      }

      &-name {
        font-weight: 600;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        gap: 0.5rem;
      }

      &-link {
        height: 1.2rem;
        width: 1.2rem;
        min-width: 1.2rem;
        cursor: pointer;
      }

      &-remove {
        margin-left: auto;
      }

      &-role {
        color: #60666b;
      }
    }
  }
}

::v-deep .b-skeleton {
  width: unset !important;
}
</style>
