<template>
  <div>
    <InlineSearch
      v-if="!showCustomInput"
      :key="searchKey"
      ref="personsearch"
      :placeholder="placeholder"
      :title="title"
      :search-function="searchPeople"
      :debounce-ms="1000"
      :show-last="allowCustom"
      :query-validator="validateQuery"
      :position-fixed="positionFixed"
      v-on="{
        ...$listeners,
        lastClicked: () => (showCustomInput = true)
      }"
    >
      <template #item="{ item }">
        <div class="user-search-item">
          <Avatar :user="item" fallback-icon="user" size="m" />
          <div class="user-search-item-content">
            <div class="user-search-item-name">
              {{ $umodel.full_name(item) }}
              <img
                src="@/assets/logo/linkedin.svg"
                alt=""
                class="user-search-item-link"
                @click.stop="() => gotoLinkedIn(item)"
              />
            </div>
            <p class="user-search-item-role">
              {{ item.role }}
            </p>
          </div>
        </div>
      </template>
      <template #last="{ query }">
        <div v-if="allowCustom" class="user-search-add">
          <img
            src="@/assets/icons/plus-medium.svg"
            alt=""
            class="user-search-add-icon"
          />
          <p class="user-search-add-text">
            Add by LinkedIn URL or job title:
            <span class="bold">{{ query }}</span>
          </p>
        </div>
      </template>
    </InlineSearch>
    <div v-else class="user-search-custom">
      <p class="user-search-custom-header">
        Please enter the person's LinkedIn URL or job title
      </p>
      <div class="user-search-custom-inputs">
        <div class="user-search-custom-inputs-text">
          <TextInput
            v-model="customLinkedin"
            placeholder="https://www.linkedin.com/in/..."
            class="user-search-custom-inputs-text-input"
            :disabled="!!customRole"
          />
          <span class="centered">OR</span>
          <TextInput
            v-model="customRole"
            placeholder="Job title"
            class="user-search-custom-inputs-text-input"
            :disabled="!!customLinkedin"
          />
        </div>
        <div class="user-search-custom-inputs-btns">
          <Button text="Cancel" type="white" @click="clearCustom" />
          <Button text="Save" @click="submitCustom" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getAccountPersons } from '@/services/accountService'
import Avatar from '@c/library/Avatar.vue'
import Button from '@c/library/Button.vue'
import TextInput from '@c/library/TextInput.vue'
import InlineSearch from '@c/library/InlineSearch.vue'

export default {
  name: 'AccountPersonSearch',
  components: { Avatar, InlineSearch, Button, TextInput },
  props: {
    account: {
      type: Object,
      default: () => ({})
    },
    title: {
      type: String,
      default: ''
    },
    allowCustom: {
      type: Boolean,
      default: false
    },
    positionFixed: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    showCustomInput: false,
    customLinkedin: '',
    customRole: '',
    searchKey: 0
  }),
  computed: {
    placeholder() {
      const n = this.account?.organisation?.name
      return `Find people ${n ? 'in ' : ''}${n || ''}${
        this.allowCustom ? ' or add them by LinkedIn link or job title' : ''
      }`
    }
  },
  methods: {
    async searchPeople(query) {
      let people = []
      try {
        const res = await getAccountPersons({
          workspace_id: this.$route.params.workspace_id,
          account_id: this.account.uuid,
          query
        })
        people = res || []
      } catch (e) {
        this.$console.debug(
          'Something went wrong when loading user search results',
          e
        )
        this.$toast.error(e, 'loading your user search results')
        people = []
      }
      return people
    },
    validateQuery(query) {
      if (query.includes('https://') && query.includes('linkedin')) {
        this.$emit('custom', 'linkedin', query)
        this.searchKey++
        return false
      }
      return true
    },
    clearCustom() {
      this.customLinkedin = ''
      this.customRole = ''
      this.showCustomInput = false
    },
    submitCustom() {
      this.$emit(
        'custom',
        this.customLinkedin ? 'linkedin' : 'role',
        this.customLinkedin || this.customRole
      )
      this.clearCustom()
    },
    gotoLinkedIn(item) {
      window.open(item.url, '_blank')
    },
    blur() {
      this.$refs.personsearch.blur()
    }
  }
}
</script>

<style lang="scss" scoped>
.user-search {
  &-item {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 1rem;

    &-content {
      display: flex;
      flex-flow: column nowrap;
      gap: 0.25rem;
    }

    &-name {
      font-weight: 600;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      gap: 0.5rem;
    }

    &-link {
      height: 1.2rem;
      min-height: 1.2rem;
      max-height: 1.2rem;
      width: 1.2rem;
      min-width: 1.2rem;
      max-width: 1.2rem;
      cursor: pointer;
    }

    &-role {
      color: #60666b;
    }
  }

  &-add {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.6rem;
    width: 100%;
    background: rgba(#000, 0.08);
    filter: brightness(0) saturate(100%) invert(25%) sepia(95%) saturate(2915%)
      hue-rotate(217deg) brightness(97%) contrast(103%);

    &-text {
      & > .bold {
        font-weight: 600;
      }
    }

    &-icon {
      height: 1.2rem;
    }
  }

  &-custom {
    display: flex;
    flex-flow: column nowrap;
    gap: 0.75rem;
    padding: 1rem;
    border: 1px solid rgba(#000, 8%);
    border-radius: 8px;
    position: relative;
    margin-bottom: 1rem;

    &-header {
      color: #60666b;
    }

    &-inputs {
      display: flex;
      flex-flow: column nowrap;
      gap: 1rem;

      &-text {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        gap: 0.5rem;
        flex: 1;

        &-input {
          flex: 1;
        }
      }

      &-btns {
        align-self: flex-end;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        gap: 1rem;
      }
    }
  }
}
</style>
