<template>
  <div class="meeting-outputs">
    <div class="meeting-outputs-filters">
      <MeetingOutputsFilters
        :key="selectedFiltersKey"
        :filters="filters"
        :selected="selectedFilters"
        :loading="loadingTemplates"
        @select="handleSelectFilter"
        @selectAll="handleSelectAll"
      />
    </div>
    <div class="meeting-outputs-templates">
      <MeetingOutputsTemplates
        :templates="filteredTemplates"
        :selected="selectedUuids"
        :loading="loadingTemplates"
        :generated="generatedTemplates"
        @select="handleSelectTemplate"
      >
        <template #header>
          <TextInput
            v-model="query"
            left-icon="search"
            placeholder="Search..."
            class="meeting-outputs-templates-search"
          />
        </template>
      </MeetingOutputsTemplates>
    </div>
    <div class="meeting-outputs-selection">
      <MeetingOutputsSelection
        :key="selectionKey"
        :selected="selected"
        :settings="selectedSettings"
        :meeting="meeting"
        :hide-settings="hideSettings"
        @select="handleRemoveTemplate"
        @settings="handleChangeSettings"
      />
    </div>
  </div>
</template>

<script>
import TextInput from '@c/library/TextInput.vue'
import MeetingOutputsFilters from './MeetingOutputsFilters.vue'
import MeetingOutputsTemplates from './MeetingOutputsTemplates.vue'
import MeetingOutputsSelection from './MeetingOutputsSelection.vue'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'MeetingOutputsSelect',
  components: {
    TextInput,
    MeetingOutputsFilters,
    MeetingOutputsTemplates,
    MeetingOutputsSelection
  },
  props: {
    meeting: {
      type: Object,
      default: () => ({})
    },
    forceSelected: {
      type: Array,
      default: () => []
    },
    hideSettings: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    templates: [],
    loadingTemplates: false,
    selectedFilters: {},
    selectedFiltersKey: 0,
    query: '',
    selected: [],
    selectedSettings: {},
    selectionKey: 0
  }),
  computed: {
    ...mapGetters(['currentWorkspace', 'currentUser']),
    generateDisabled() {
      return !this.selected?.length
    },
    selectedUuids() {
      return this.selected.map((s) => s.uuid)
    },
    visibleTemplates() {
      return this.templates.filter((t) => t.status === 'visible')
    },
    filteredTemplates() {
      this.selectedFiltersKey
      let selected = Object.keys(this.selectedFilters)
      return this.visibleTemplates.filter(
        (t) =>
          t.name.toLowerCase().includes(this.query.toLowerCase()) &&
          selected.every((filter) => {
            const values =
              filter === 'toolkits'
                ? t[filter].map((tk) => tk.uuid)
                : [t[filter]]
            return values.some((v) => this.selectedFilters[filter].includes(v))
          })
      )
    },
    generatedTemplates() {
      return (this.meeting.outputs || []).map((o) => o.tool?.uuid || '')
    },
    templateToolkits() {
      return this.visibleTemplates.reduce((acc, t) => {
        ;(t.toolkits || []).forEach((tk) => {
          if (!acc.some((acc_tk) => acc_tk.uuid === tk.uuid)) acc.push(tk)
        })
        return acc
      }, [])
    },
    templateTypes() {
      return this.visibleTemplates.reduce((acc, t) => {
        if (!acc.includes(t.type)) acc.push(t.type)
        return acc
      }, [])
    },
    templateSources() {
      return this.visibleTemplates.reduce((acc, t) => {
        if (!acc.includes(t.provider)) acc.push(t.provider)
        return acc
      }, [])
    },
    filters() {
      return [
        ...(this.templateToolkits.length
          ? [
              {
                id: 'toolkits',
                name: 'Deal stage',
                items: this.templateToolkits.map((tk) => ({
                  id: tk.uuid,
                  name: tk.name
                }))
              }
            ]
          : []),
        ...(this.templateTypes.length
          ? [
              {
                id: 'type',
                name: 'Asset type',
                items: this.templateTypes.map((type) => ({
                  id: type,
                  name: type.capitalize()
                }))
              }
            ]
          : []),
        ...(this.templateSources.length
          ? [
              {
                id: 'provider',
                name: 'Source',
                items: this.templateSources.map((source) => ({
                  id: source,
                  name:
                    { core: 'uman', custom: this.currentWorkspace.name }[
                      source
                    ] || source.capitalize()
                }))
              }
            ]
          : [])
      ]
    }
  },
  created() {
    this.loadTemplates()
  },
  methods: {
    ...mapActions(['getTemplates']),
    async loadTemplates() {
      try {
        this.loadingTemplates = true
        this.templates = await this.getTemplates({
          workspace_id: this.$route.params.workspace_id
        })
        if (this.forceSelected.length) {
          this.selected = this.templates.filter((t) =>
            this.forceSelected.includes(t.uuid)
          )
        }
      } catch (e) {
        this.$console.debug('Error when loading templates', e)
        this.$toast.error(e, 'loading templates')
      } finally {
        this.loadingTemplates = false
      }
    },
    async handleSelectFilter(filter, option) {
      if ((this.selectedFilters[filter.id] || []).includes(option.id)) {
        const selected = this.selectedFilters[filter.id].filter(
          (id) => id !== option.id
        )
        if (selected.length) this.selectedFilters[filter.id] = selected
        else delete this.selectedFilters[filter.id]
      } else {
        this.selectedFilters[filter.id] = [
          ...(this.selectedFilters[filter.id] || []),
          option.id
        ]
      }
      this.selectedFiltersKey++
    },
    async handleSelectAll(filter, item) {
      const selectedIds = this.selected.map((s) => s.uuid)
      const values =
        filter.id === 'toolkits'
          ? this.visibleTemplates.filter((t) =>
              t[filter.id].some((tk) => tk.uuid === item.id)
            )
          : this.visibleTemplates.filter((t) => t[filter.id] === item.id)
      const allSelected = values.every((v) => selectedIds.includes(v.uuid))
      if (allSelected) {
        values.forEach(this.handleRemoveTemplate)
      } else {
        values
          .filter((v) => !selectedIds.includes(v.uuid))
          .forEach(this.handleSelectTemplate)
      }
    },
    handleSelectTemplate(e) {
      if (this.selectedUuids.includes(e.uuid)) {
        this.selected = this.selected.filter((s) => s.uuid !== e.uuid)
        if (this.selectedSettings[e.uuid]) delete this.selectedSettings[e.uuid]
      } else {
        this.selectedSettings[e.uuid] = {
          language:
            e.type === 'presentation'
              ? this.currentWorkspace.language
              : this.currentUser.language
        }
        this.selected.push(e)
      }
      this.emitSelection()
    },
    handleChangeSettings(e, settings) {
      this.selectedSettings[e.uuid] = settings
      this.selectionKey++
      this.emitSelection()
    },
    handleRemoveTemplate(template) {
      this.selected = this.selected.filter((s) => s.uuid !== template.uuid)
      if (this.selectedSettings[template.uuid])
        delete this.selectedSettings[template.uuid]
      this.emitSelection()
    },
    emitSelection() {
      this.$emit('select', this.selected, this.selectedSettings)
    }
  }
}
</script>

<style lang="scss" scoped>
.meeting-outputs {
  --output-modal-width: calc(100vw - 5rem);
  max-height: 100%;
  height: 100%;
  width: var(--output-modal-width);
  display: flex;
  flex-flow: row nowrap;
  flex: 1;
  overflow-y: auto;

  &-filters {
    flex: 1;
    height: 100%;
    max-height: 100%;
    overflow-y: auto;
  }

  &-templates {
    flex: 3;
    height: 100%;
    max-height: 100%;
    overflow-y: auto;
    border-left: 1px solid rgba(#000, 0.08);
    border-right: 1px solid rgba(#000, 0.08);

    &-search {
      width: 50%;
    }
  }

  &-selection {
    flex: 1;
    height: 100%;
    max-height: 100%;
    overflow-y: auto;
  }
}
</style>
