var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "meeting-audience" },
    [
      _c("p", { staticClass: "meeting-audience-header" }, [
        _vm._v("Who will you be meeting?")
      ]),
      _c("AccountPersonSearch", {
        ref: "audiencesearch",
        staticClass: "meeting-audience-block",
        attrs: {
          account: _vm.account,
          "allow-custom": true,
          "position-fixed": true
        },
        on: { submit: _vm.addFromSearch, custom: _vm.addCustom }
      }),
      _vm.selection && _vm.selection.length
        ? _c(
            "div",
            { staticClass: "meeting-audience-items meeting-audience-block" },
            _vm._l(_vm.selection, function(person) {
              return _c(
                "div",
                {
                  key: person.linkedin_url || person.role,
                  staticClass: "meeting-audience-items-item"
                },
                [
                  _c(
                    "div",
                    { staticClass: "meeting-audience-items-item-info" },
                    [
                      _c("Avatar", {
                        attrs: {
                          user: person.linkedin_url
                            ? {
                                avatar: person.avatar,
                                username: _vm.$umodel.full_name(person)
                              }
                            : {},
                          "fallback-icon": "user",
                          size: "m"
                        }
                      }),
                      _c(
                        "div",
                        { staticClass: "meeting-audience-items-item-content" },
                        [
                          _c(
                            "div",
                            { staticClass: "meeting-audience-items-item-name" },
                            [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    person.first_name
                                      ? _vm.$umodel.full_name(person)
                                      : person.role || person.linkedin_url
                                  )
                                )
                              ]),
                              person.linkedin_url
                                ? _c("img", {
                                    staticClass:
                                      "meeting-audience-items-item-link",
                                    attrs: {
                                      src: require("@/assets/logo/linkedin.svg"),
                                      alt: ""
                                    },
                                    on: {
                                      click: function($event) {
                                        $event.stopPropagation()
                                        return function() {
                                          return _vm.gotoLinkedIn(person)
                                        }.apply(null, arguments)
                                      }
                                    }
                                  })
                                : _vm._e(),
                              _c("Button", {
                                staticClass:
                                  "meeting-audience-items-item-remove",
                                attrs: {
                                  icon: "bin",
                                  type: "white",
                                  size: "xs"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.$emit("remove", person)
                                  }
                                }
                              })
                            ],
                            1
                          ),
                          person.first_name && person.role
                            ? _c(
                                "p",
                                {
                                  staticClass:
                                    "meeting-audience-items-item-role"
                                },
                                [_vm._v(" " + _vm._s(person.role) + " ")]
                              )
                            : _vm._e()
                        ]
                      )
                    ],
                    1
                  )
                ]
              )
            }),
            0
          )
        : _vm._e(),
      _c("MeetingORISuggestions", {
        attrs: {
          type: "audience",
          loading: _vm.suggestionsLoading,
          error: _vm.suggestionsError,
          selected: _vm.selection,
          suggestions: _vm.suggestions
        },
        on: { retry: _vm.loadSuggestions, add: _vm.addFromSearch }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }