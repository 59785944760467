var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.showCustomInput
        ? _c(
            "InlineSearch",
            _vm._g(
              {
                key: _vm.searchKey,
                ref: "personsearch",
                attrs: {
                  placeholder: _vm.placeholder,
                  title: _vm.title,
                  "search-function": _vm.searchPeople,
                  "debounce-ms": 1000,
                  "show-last": _vm.allowCustom,
                  "query-validator": _vm.validateQuery,
                  "position-fixed": _vm.positionFixed
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "item",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _c(
                            "div",
                            { staticClass: "user-search-item" },
                            [
                              _c("Avatar", {
                                attrs: {
                                  user: item,
                                  "fallback-icon": "user",
                                  size: "m"
                                }
                              }),
                              _c(
                                "div",
                                { staticClass: "user-search-item-content" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "user-search-item-name" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$umodel.full_name(item)) +
                                          " "
                                      ),
                                      _c("img", {
                                        staticClass: "user-search-item-link",
                                        attrs: {
                                          src: require("@/assets/logo/linkedin.svg"),
                                          alt: ""
                                        },
                                        on: {
                                          click: function($event) {
                                            $event.stopPropagation()
                                            return function() {
                                              return _vm.gotoLinkedIn(item)
                                            }.apply(null, arguments)
                                          }
                                        }
                                      })
                                    ]
                                  ),
                                  _c(
                                    "p",
                                    { staticClass: "user-search-item-role" },
                                    [_vm._v(" " + _vm._s(item.role) + " ")]
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ]
                      }
                    },
                    {
                      key: "last",
                      fn: function(ref) {
                        var query = ref.query
                        return [
                          _vm.allowCustom
                            ? _c("div", { staticClass: "user-search-add" }, [
                                _c("img", {
                                  staticClass: "user-search-add-icon",
                                  attrs: {
                                    src: require("@/assets/icons/plus-medium.svg"),
                                    alt: ""
                                  }
                                }),
                                _c(
                                  "p",
                                  { staticClass: "user-search-add-text" },
                                  [
                                    _vm._v(
                                      " Add by LinkedIn URL or job title: "
                                    ),
                                    _c("span", { staticClass: "bold" }, [
                                      _vm._v(_vm._s(query))
                                    ])
                                  ]
                                )
                              ])
                            : _vm._e()
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  1909448731
                )
              },
              Object.assign({}, _vm.$listeners, {
                lastClicked: function() {
                  return (_vm.showCustomInput = true)
                }
              })
            )
          )
        : _c("div", { staticClass: "user-search-custom" }, [
            _c("p", { staticClass: "user-search-custom-header" }, [
              _vm._v(" Please enter the person's LinkedIn URL or job title ")
            ]),
            _c("div", { staticClass: "user-search-custom-inputs" }, [
              _c(
                "div",
                { staticClass: "user-search-custom-inputs-text" },
                [
                  _c("TextInput", {
                    staticClass: "user-search-custom-inputs-text-input",
                    attrs: {
                      placeholder: "https://www.linkedin.com/in/...",
                      disabled: !!_vm.customRole
                    },
                    model: {
                      value: _vm.customLinkedin,
                      callback: function($$v) {
                        _vm.customLinkedin = $$v
                      },
                      expression: "customLinkedin"
                    }
                  }),
                  _c("span", { staticClass: "centered" }, [_vm._v("OR")]),
                  _c("TextInput", {
                    staticClass: "user-search-custom-inputs-text-input",
                    attrs: {
                      placeholder: "Job title",
                      disabled: !!_vm.customLinkedin
                    },
                    model: {
                      value: _vm.customRole,
                      callback: function($$v) {
                        _vm.customRole = $$v
                      },
                      expression: "customRole"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "user-search-custom-inputs-btns" },
                [
                  _c("Button", {
                    attrs: { text: "Cancel", type: "white" },
                    on: { click: _vm.clearCustom }
                  }),
                  _c("Button", {
                    attrs: { text: "Save" },
                    on: { click: _vm.submitCustom }
                  })
                ],
                1
              )
            ])
          ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }